$(document).ready(function() {
  $('.product__option-actions').each((index, elem) => {
    const _this = $(elem)
    const setLess = _this.children('.btn-less')
    const setMore = _this.children('.btn-more')

    // постоянные значения
    const product_id = _this.find('.input-product_id').val()
    const option = _this.find('.input-option')

    const option_name = _this.find('.input-option').attr('name')
    const option_value = _this.find('.input-option').val()

    // переменные знчения
    const inputQuantity = _this.find('.input-quantity')
    


    setLess.on('click', function() {
      let quantity = Number(inputQuantity.val())

      // если значение ноль - ничего делать не будем
      if(quantity > 0) {
        quantity = quantity - 1

        inputQuantity.val(quantity)

        if(quantity === 0) {
          // отменим выделение товара в корзине
          _this.children('.product__option-data').addClass('product__option-data--empty')
        }

        // добавление товара в корзину
        cart.add(product_id, quantity, option_name, option_value)
      }
    })

    setMore.on('click', function() {
      let quantity = Number(inputQuantity.val()) + 1

      // увеличим значение на еденицу (всегда)
      inputQuantity.val(quantity)

      // выделим что товар есть в корзине
      _this.children('.product__option-data').removeClass('product__option-data--empty')

      // добавление товара в корзину
      cart.add(product_id, quantity, option_name, option_value)
    })
  })

  $('.js-toggle').on('click', function(e) {

    const elem = $(e.currentTarget)
    const parent = elem.parents(elem.data('parent'))
    const className = elem.data('class')

    parent.toggleClass(className)
  })
})

// Cart add remove functions
var cart = {
  'add': function(product_id, quantity, option_name, option_value) {
    $.ajax({
      url: 'index.php?route=checkout/cart/add',
      type: 'post',
      data: 'product_id=' + product_id + '&quantity=' + ((typeof(quantity) != 'undefined' ? quantity : 1) + '&' + option_name + '=' + option_value),
      dataType: 'json',
      beforeSend: function() {},
      complete: function() {},
      success: function(json) {

        if (json['redirect']) {
          location = json['redirect'];
        }

        if (json['success']) {
          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $('#cart-total').text(json['cart_total']);
            $('#total .service-price--q').text(json['total']);
          }, 100);
        }
      },
      error: function(xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
    });
  },
  'update': function(key, quantity) {
    $.ajax({
      url: 'index.php?route=checkout/cart/edit',
      type: 'post',
      data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
      dataType: 'json',
      beforeSend: function() {},
      complete: function() { },
      success: function(json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $('#cart-total').text(json['cart_total']);
          $('#total .service-price--q').text(json['total']);
        }, 100);

        if (getURLVar('route') == 'checkout/reserve' || getURLVar('route') == 'checkout/checkout') {
          location = 'index.php?route=checkout/reserve';
        }
      },
      error: function(xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
    });
  },
  'remove': function(key) {
    $.ajax({
      url: 'index.php?route=checkout/cart/remove',
      type: 'post',
      data: 'key=' + key,
      dataType: 'json',
      beforeSend: function() {},
      complete: function() {},
      success: function(json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $('#cart-total').text(json['cart_total']);
          $('#total .service-price--q').text(json['total']);
        }, 100);

        if (getURLVar('route') == 'checkout/reserve' || getURLVar('route') == 'checkout/checkout') {
          location = 'index.php?route=checkout/reserve';
        } 
      },
      error: function(xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
    });
  }
}

// Функция добавления toast на страницу
var addToastToDocument = function(head, body, delay = 5000) {

	if(!head && !body) return

	// сформируем toast html
	var html = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="true" data-delay="' + delay + '">';
  html += '  <div class="toast-header">';
  if(head)
    html += '    <strong class="mr-auto">' + head + '</strong>';
  else
  	html += '    <strong class="mr-auto">Товар добавлен</strong>';
  html += '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">';
  html += '      <span aria-hidden="true">&times;</span>';
  html += '    </button>';
  html += '  </div>';
  if(body)
    html += '  <div class="toast-body">' + body + '</div>';
  html += ' </div>';

	// Добавим в DOM
  $('.toast-outer').append(html)

  // найдем его ссылку
  var lastToast = $('.toast-outer').find('.toast').last()

  // покажем toast
  lastToast.toast('show')

  // после скрытия - удалим из DOM
  lastToast.on('hidden.bs.toast', function () {
    $(this).remove()
  })
}
